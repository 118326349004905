import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { REST_API_BASE_URL } from "../config"
import * as errorHandling from "../redux/error-handling/actions"
import { composeErrorMessage } from "../redux/utils/utils.saga"

const browserSignature = _ => {

    const windowObj =  window || global;
  
    // Count Browser window object keys
    const windowObjCount = _ => {
      return 1/*
        const keys = [];
        for (let i in windowObj) {
          keys.push(i)
        }
        return keys.length.toString(36);*/
    }  
    // window obj and navigator aggregate
    const pad = (str, size) => {
        return (new Array(size + 1).join('0') + str).slice(-size);
    };
  
    // Browser mimiTypes and User Agent count
    const navi = (navigator.mimeTypes.length + navigator.userAgent.length).toString(36);
    const padString = pad(navi + windowObjCount(), 4);
    // Browser screen specific properties
    const width = windowObj.screen.width.toString(36)
    const height = windowObj.screen.height.toString(36)
    const availWidth = windowObj.screen.availWidth.toString(36)
    const availHeight = windowObj.screen.availHeight.toString(36)
    const colorDepth = windowObj.screen.colorDepth.toString(36)
    const pixelDepth = windowObj.screen.pixelDepth.toString(36)
    // Base64 encode
    return btoa(padString + width + height + availWidth + availHeight + colorDepth + pixelDepth);
}

const baseQueryWithAuth = fetchBaseQuery({
	baseUrl: REST_API_BASE_URL,
	prepareHeaders: (headers, { getState }) => {
        headers.set('authorization', getState().user?.token)
        headers.set('Device', browserSignature())
		return headers
	},
	responseHandler: "content-type"
})

const baseQuery = async (args, api, extraOptions) => {
	let result = await baseQueryWithAuth(args, api, extraOptions)
	const { error } = result
  if ( error ) {
    console.error(args, error)
    const message = composeErrorMessage(
      args.method || 'GET', 
      args.url, 
      {statusCode: error.status, errorKey: error.data || error.statusText}
    );
    window.store.dispatch(errorHandling.requestFailed(message))
  }
	return result
}

export const api = createApi({
	reducerPath: "api",
	tagTypes: [
    "Client", 
    "ClientOutlookIntegrationHealth",
    "ClientOutlookIntegrationHealthHistory",
    "ContactStatuses", 
    "ServicePointStatistics", 
    "RepresentativeStatistics",
    "RepresentativeBookingTimeValidation"
  ],
	baseQuery: baseQuery,
	endpoints: () => ({}),
})

