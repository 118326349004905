import { put, call, select, takeEvery } from 'redux-saga/effects';

import * as callActions from '../call/actions';
import * as contactActions from '../contacts/actions';
import * as socketActions from './actions';
import * as bookingActions from '../booking/actions';
import * as userActions from '../user/actions';
import * as bgprocActions from '../background-process-log/actions';
import * as businessActions from '../business-db/actions';
import * as notificationActions from '../notification/actions';

import { ALL_SELECTED } from '../../consts';

export const getCampaignId = state => state.contacts.contactCard.campaignId;
export const getRepresentativeId = state => state.contacts.contactCard.representativeId;
export const getProjectId = state => state.contacts.contactCard.projectId;
export const getServicePointId = state =>
  state.contacts.contactCard.servicePointId;
export const getCall = state => state.call.call;
export const getCurrentCallType = state => state.callLists.current;
export const getBooking = state => state.booking.booking;

function* handleSocketOnMessage(action) {

  const message = JSON.parse(action.payload.data);

  const userType = yield select(state => state.user.type)

  if ( message.type === 'notification-trigger' ) {
    yield put(notificationActions.getNotifications(userType === 'admin'))
    return
  }

  if ( userType === 'admin' ) {
    if ( message.type === 'api-status' ) {
      console.log(message)
    }
    if ( message.type === 'bgproc') {

      const admin = yield select(state => state.user.admin)

      const types = (admin.bgprocType || '').split(',')

      if ( !types.length || types.includes(message.name) ) {
        yield put(bgprocActions.addEvent(message))
        if ( ['import_businesses', 'remove_businesses'].includes(message.name) && message.status === 'success' ) {
          yield put(contactActions.getDecisionMakerStatistics())
          yield put(businessActions.getValues())
        }
       }

    }
    return
  }

  const selectedServicePointId = yield select(getServicePointId)

  /*const campaignId = yield select(getCampaignId);
  const projectId = message.projectId != null
    ? message.projectId
    : yield select(getProjectId);*/
  const servicePointId = message.servicePointId != null
    ? message.servicePointId
    : yield select(getServicePointId);

  const selectedRepresentativeId = yield select(getRepresentativeId)

  switch (message.target) {
    case 'calendar':
      // Update suggestions for this representative.
      //
      // This socket message is sent from backend whenever representative
      // calendar is changed, and it happens also when a booker reserves
      // or releases suggestions during call flow.
      if ( selectedServicePointId === servicePointId && 
          ( selectedRepresentativeId === ALL_SELECTED || 
            selectedRepresentativeId === message.representativeId ) 
      ) {/*
        yield put(
          callActions.getSuggestions({
            campaignId,
            projectId,
            representativeId: selectedRepresentativeId,
            servicePointId
          })
        );*/
      }
      break;
    case 'call':
      // End call
      if ( message.targetId ) {
        const call = yield select(getCall)
        if ( message.targetId === call.id ) {
          const currentCallType = yield select(getCurrentCallType)
          const booking = yield select(getBooking)
          console.log({ 
            answered: message.answered, 
            type: currentCallType, 
            bookingId: booking ? booking.id : null,
            noHangup: true
          })
          yield put(callActions.endCall({ 
            answered: message.answered, 
            type: currentCallType, 
            bookingId: booking ? booking.id : null,
            noHangup: true
          }));
        }
      } else {
        yield put(callActions.endedCall({ answered: message.answered }));
      }
      break;
    case 'booking':
      if (message.type === 'release') {
        // Release reserved booking
        yield put(bookingActions.releasedReservedBooking(message.bookingId));
      }
      break;
    default:
      break;
  }
}

function* handleSocketOnClose(action) {
  const expiredToken = action.payload && action.payload.event && action.payload.event.reason === 'expiredToken'
  if ( expiredToken ) {
    yield put(userActions.logout())
  }
}


const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* handleSocketOnOpen(action) {
  const userType = yield select(state => state.user.type)
  if ( userType === 'admin' ) {
    yield call(delay, 500)
    yield put(bgprocActions.getEvents())
  }
}

export const watchSocketOnMessage = takeEvery(socketActions.message, handleSocketOnMessage);

export const watchSocketOnClose = takeEvery(socketActions.closed, handleSocketOnClose);

export const watchSocketOnOpen = takeEvery(socketActions.open, handleSocketOnOpen);
